export const REQUIRED = 'REQUIRED';
export const TOO_SHORT = 'TOO_SHORT';
export const TOO_SHORT_BMCE_DIRECT = 'TOO_SHORT_BMCE_DIRECT';
export const PASSWORD_FORMAT_INVALID = 'PASSWORD_FORMAT_INVALID';
export const USER_ALREADY_EXIST = 'USER_ALREADY_EXIST';
export const PASSWORD_IS_INCORRECT = 'PASSWORD_IS_INCORRECT';
export const PASSWORD_DOES_NOT_MATCH = 'PASSWORD_DOES_NOT_MATCH';
export const EMAIL_DOES_NOT_MATCH = 'EMAIL_DOES_NOT_MATCH';
export const INVALID_EMAIL = 'INVALID_EMAIL';
export const INVALID_PASSWORD = 'INVALID_PASSWORD';
export const INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER';
export const INVALID_OTP = 'INVALID_OTP';
export const CONDITION_OF_USE_REQUIRED = 'CONDITION_OF_USE_REQUIRED';
export const INVALID_EMAIL_OR_PASSWORD = 'INVALID_EMAIL_OR_PASSWORD';
export const INVALID_BMCE_DIRECT_LOGIN_OR_PASSWORD =
  'INVALID_BMCE_DIRECT_LOGIN_OR_PASSWORD';
export const INVALID_CAPTCHA_GOOGLE = 'INVALID_CAPTCHA_GOOGLE';
export const ERROR_BMCE_SERVICE = 'ERROR_BMCE_SERVICE';
export const ERROR_FIELDS = 'ERROR_FIELDS';
export const MAX_FILE_SIZE_ERROR = 'MAX_FILE_SIZE_ERROR';
export const VERIFY_FILE_EXTENTIONS = 'VERIFY_FILE_EXTENTIONS';
export const FILE_MALICIOUS = 'FILE_MALICIOUS';
export const DUPLICATE_BMCE_DIRECT_LOGIN = 'DUPLICATE_BMCE_DIRECT_LOGIN';
export const INVALID_DATE_FORMAT = 'INVALID_DATE_FORMAT';
export const DUPLICATE_EMAIL = 'DUPLICATE_EMAIL';
export const DUPLICATE_TIERS = 'DUPLICATE_TIERS';
export const REQUIRED_GSM = 'REQUIRED_GSM';
export const ERROR_OTP_SERVICE_NBR_TENTATIVE_MAX =
  'ERROR_OTP_SERVICE_NBR_TENTATIVE_MAX';
export const WRONG_OTP = 'WRONG_OTP';
export const CIN_PLUSIEURS_TIERS = 'CIN_PLUSIEURS_TIERS';
export const ERROR_OTP_SERVICE_INVALIDE_OTP_ID =
  'ERROR_OTP_SERVICE_INVALIDE_OTP_ID';
export const ERROR_OTP_SERVICE_INVALIDE_OTP_FORMAT =
  'ERROR_OTP_SERVICE_INVALIDE_OTP_FORMAT';
export const ERROR_OTP_SERVICE_INVALIDE_PASSWORD =
  'ERROR_OTP_SERVICE_INVALIDE_PASSWORD';
export const ERROR_OTP_SERVICE_PASSWORD_TIMEOUT =
  'ERROR_OTP_SERVICE_PASSWORD_TIMEOUT';
export const ERROR_OTP_SERVICE = 'ERROR_OTP_SERVICE';
export const ERROR_CHANGE_TEL = 'ERROR_CHANGE_TEL';
export const ERROR_VALID_TEL = 'ERROR_VALID_TEL';
export const ERROR_CLIENT_SALAFIN = 'ERROR_CLIENT_SALAFIN';
export const CLIENT_WITH_MULTI_CPT = 'CLIENT_WITH_MULTI_CPT';
export const COMPTE_MULTI_TITULAIRE = 'COMPTE_MULTI_TITULAIRE';
export const ERROR_GRC_SERVICE = 'ERROR_GRC_SERVICE';
export const DATA_IN_NOT_VALIDE = 'DATA_IN_NOT_VALIDE';
export const CIN_RIB_NULLS = 'CIN_RIB_NULLS';
export const UNAUTHORIZED_USER = 'UNAUTHORIZED_USER';
export const TOKEN_BMCEDIRECT_EXPIRED = 'TOKEN_BMCEDIRECT_EXPIRED';
export const EXCEED_MAX_ATTEMPTS = 'EXCEED_MAX_ATTEMPTS';
export const ERROR_WAF = 'ERROR_WAF';
export const TOKEN_BMCEDIRECT_NOT_VALID = 'TOKEN_BMCEDIRECT_NOT_VALID';
export const CIN_IS_NULL = 'CIN_IS_NULL';
export const INVALID_RIB = 'INVALID_RIB';

// Salafin Errors Code
export const E3 = 'ERROR_SALAFIN_BAREME';
export const E34 = 'E34';
export const E35 = 'E35';
export const E36 = 'E36';
export const E37 = 'E37';
export const E38 = 'E38';
export const E39 = 'E39';

// Preattribution BMCE DIRECT ERROR :
export const DURATION_OR_AMOUNT_NULL = 'DURATION_OR_AMOUNT_NULL';
