import axios from 'axios';
import { env } from 'env';
import { AuthAT } from 'features/auth/actions.auth';
import { SimulationAT } from 'features/home/actions.simulation';
import { SituationAT } from 'features/situation/actions.situation';
import { CongratulationAT } from 'features/congratulation/actions.congratulation';
import { PropositionAT } from 'features/proposition/actions.proposition';
import { RecapAT } from 'features/recap/actions.recap';
import { CaseTrackingAT } from 'features/suivieDossier/actions.caseTracking';
import { UpdateInformationAT } from 'features/updateInformation/actions.updateInformation';
import { UploadDocumentAT } from 'features/upload/actions.upload';
import store from 'store';
import {
  ERROR_BMCE_SERVICE,
  ERROR_CLIENT_SALAFIN,
  ERROR_FIELDS,
  ERROR_GRC_SERVICE,
  ERROR_OTP_SERVICE,
  UNAUTHORIZED_USER,
} from 'common/utils/validateJs/contants';

export const axiosInstance = axios.create({
  baseURL: env.apiPath,
  // timeout: 1000,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response && error.response.status;
    const message = error.response.data.errors?.[0].errors?.[0];
    const messageUnauth = error.response.data.message;
    const servicesError = [
      ERROR_BMCE_SERVICE,
      ERROR_CLIENT_SALAFIN,
      ERROR_GRC_SERVICE,
      ERROR_OTP_SERVICE,
      ERROR_FIELDS,
    ];

    if (status === 500 || status === 404) {
      store.dispatch({
        type: SimulationAT.isOnline,
        value: { check: false, status, error: 'back_down' },
      });
    } else if (status === 400) {
      if (servicesError.includes(message)) {
        store.dispatch({
          type: SimulationAT.isOnline,
          value: { check: false, status, error: message },
        });
      }
    } else if (status === 401 && messageUnauth === UNAUTHORIZED_USER) {
      store.dispatch({ type: SimulationAT.isOnline, value: { check: false } });
      store.dispatch({ type: SimulationAT.initSimulationAT });
      store.dispatch({ type: SituationAT.initSituationAT });
      store.dispatch({ type: CongratulationAT.initCongratulationAT });
      store.dispatch({ type: PropositionAT.initPropositionAT });
      store.dispatch({ type: RecapAT.initRecapAT });
      store.dispatch({ type: CaseTrackingAT.initCaseTrackingAT });
      store.dispatch({ type: UpdateInformationAT.initUpdateInformationAT });
      store.dispatch({ type: UploadDocumentAT.initUploadDocumentAT });
      store.dispatch({ type: AuthAT.loGout });
    }

    return Promise.reject(error);
  },
);

export const apiPost = (url: string, data: any, config?: any) => {
  return axiosInstance.post(url, data, config);
};

export const apiGet = (url: string, config?: any) => {
  return axiosInstance.get(url, config);
};
